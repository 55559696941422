import { Injectable } from "@angular/core";
import * as Forge from 'node-forge';
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class RSAService {
	constructor() {}

    encryptWithPublicKey(valueToEncrypt: string): string {
        const decodedPublicKey = atob(environment.rsaPublicKeyBase64);
        const rsa = Forge.pki.publicKeyFromPem(decodedPublicKey);
        return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
    }
}
