import { MotorQuotationInterface } from "./../../interface/motor-quotation.interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { AESService } from "src/app/share/service/aes.service";
import { RSAService } from "src/app/share/service/rsa.service";
import { EncryptedPayload } from "src/app/share/interface/common.interface";

@Injectable({
  providedIn: "root",
})
export class MotorQuotationDataService {
  private basePath: string = `${environment.url}/api/Microsite/`;

  constructor(private http: HttpClient,
		private aesService: AESService, 
		private rsaService: RSAService) {}

  submit(val: MotorQuotationInterface) {
    const aesKeyValue = this.aesService.aesKey();
    const encAESKey = this.rsaService.encryptWithPublicKey(aesKeyValue);
		const encJson = this.aesService.encrypt(JSON.stringify(val));

		const payload: EncryptedPayload = {
			data: encJson,
			key: encAESKey
		};

    return this.http.post<{
      isSuccess: boolean;
      isVehicleOverage: boolean;
      isEhailing: boolean;
      data: string;
    }>(
			`${this.basePath}Submit`, 
      payload
		);
  }

  submitExtraProduct(id: string, typeOfSum: string, idList: string[], headers?: HttpHeaders) {
    return this.http.post(`${this.basePath}SubmitExtraProduct/${id}`, {
      typeOfSum,
      selectedMotorProductIds: idList,
    }, { 
      headers 
    });
  }
}
