import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
  ProductAddOnInterface,
  ProductInterface,
  QuotationInterface,
  MotorRequestInterface,
  VehicleListingInterface,
  PromoCodeInterface,
  ProductDocumentInterface,
  TRPaymentPayloadInterface,
} from "../interface/motor.interface";

@Injectable({
  providedIn: "root",
})
export class MotorDataDataService {
  private basePath: string = `${environment.url}/api/Motor/`;

  constructor(private http: HttpClient) {}

  getDetailById(id: string, headers?: HttpHeaders) {
    return this.http.get<MotorRequestInterface>(
      `${this.basePath}GetRequestInfoById/${id}`, { headers }
    );
  }

	getQuotationById(id: string, getProductDocument?: boolean, headers?: HttpHeaders) {
    let path = `${this.basePath}GetQuotation/${id}`;
    if (getProductDocument) {
      path += "?getProductDocument=true";
    }

		return this.http.get<QuotationInterface[]>(path, { headers });
	}

  getAccessList(id: string) {
    return this.http.get<any>(`${this.basePath}GetMotorExcess/${id}`);
  }

  getVehicleListing(limit: number, offset: number, text: string, make: string) {
    return this.http.post<VehicleListingInterface[]>(
      `${this.basePath}GetVehicleModelListing`,
      {
        limit,
        offset,
        text,
        make,
      },
    );
  }

  getAllProduct(id: string, headers?: HttpHeaders) {
    return this.http.get(`${this.basePath}GetAllProduct/${id}`, { headers });
  }

  getNonQuoteProduct(id: string) {
    return this.http.get<ProductInterface[]>(
      `${this.basePath}GetNonQuoteProduct/${id}`,
    );
  }

  getAddOn(id: string, quotationId: string, headers?: HttpHeaders) {
    return this.http.get<ProductAddOnInterface[]>(
      `${this.basePath}GetAddOn/${id}?quotationId=${quotationId}`, { headers }
    );
  }

  applyPromoCode(
    id: string,
    code: string,
    totalGrossPremium: number,
    motorQuotationId?: string, 
    headers?: HttpHeaders
  ) {
    return this.http.post<PromoCodeInterface>(
      `${this.basePath}PromoCode/${id}`,
      {
        code,
        totalGrossPremium,
        motorQuotationId,
      }, 
      { 
        headers 
      }
    );
  }

  editRequest(id: string, val: any, headers?: HttpHeaders) {
    return this.http.post<void>(`${this.basePath}EditRequest/${id}`, val, { headers });
  }

  getProductDocumentById(id: string, language: string, insuranceType: string) {
    return this.http.get<ProductDocumentInterface[]>(
      `${this.basePath}GetProductDocument?id=${id}&language=${language}&insuranceType=${insuranceType}`,
    );
  }

  generatePayment(id: string, val: any, headers?: HttpHeaders) {
    return this.http.post<{ id: string }>(
      `${this.basePath}GeneratePayment/${id}`,
      val, 
      { headers }
    );
  }

  generateTopupPayment(id: string, value: TRPaymentPayloadInterface) {
    return this.http.post<{ id: string }>(
      `${this.basePath}GenerateTopUpPayment/${id}`,
      value,
    );
  }

  getPaymentById(id: string) {
    return this.http.get<{
      provider: "IPay88" | "Shopback" | "Razer" | "Payex";
      data: string;
    }>(`${this.basePath}GetPayment/${id}`);
  }

  checkPayment(refNo: string) {
    return this.http.get<{ status: boolean; data: any; amount: number }>(
      `${this.basePath}CheckPayment?refNo=${refNo}`,
    );
  }

  uploadPhoto(id: string, file: any) {
    return this.http.post<{ id: string }>(
      `${this.basePath}UploadPolicyPhotos/${id}`,
      file,
    );
  }

  deletePolicyPhoto(id: string) {
    return this.http.get(`${this.basePath}DeletePolicyPhoto/${id}`);
  }

  getPolicyPhotos(id: string, includeBase64InsteadOfUrl?: boolean) {
    let path = `${this.basePath}GetPolicyPhotos/${id}`;
    if (includeBase64InsteadOfUrl) {
      path += "?includeBase64InsteadOfUrl=true";
    }

    return this.http.get(path);
  }

  getPolicyTopUpDetail(id: string) {
    return this.http.get(`${this.basePath}GetPolicyTopUpDetail/${id}`);
  }

  editCarVariant(linkId: string, vehicleId: string, headers?: HttpHeaders) {
    return this.http.post<any>(
      `${this.basePath}EditCarVariant/${linkId}?vehicleId=${vehicleId}`,
      null, 
      { headers }
    );
  }

  getEPPRates() {
    return this.http.get(`${this.basePath}GetEppRates`);
  }

  getAvailableProduct(id: string, headers?: HttpHeaders) {
    return this.http.get(`${this.basePath}GetAvailableProductByLinkId/${id}`, { headers });
  }

  checkIsOfficeHour() {
    return this.http.get(`${this.basePath}CheckIsOfficeHour`, {
      responseType: "text",
    });
  }

  getAvailableSalesCampaign(id?: string, headers?: HttpHeaders) {
    let path = `${this.basePath}GetAvailableSalesCampaign`;
    
    if (id && id.trim()) {
      path += `?id=${id}`;
    }
  
    return this.http.get(path, { headers });
  }

  getAvailableBanners() {
    return this.http.get(`${this.basePath}GetActiveBanners`);
  }
}
